import { useRouter } from 'next/router';

import { Loading } from '@parsec/components';

import { useEnabledPages } from 'lib/hooks';

function HomePage() {
  const enabledPages = useEnabledPages();
  const router = useRouter();

  if (!enabledPages || enabledPages.isLoading) return <Loading loading />;

  const membersEnabled = Boolean(enabledPages.members);
  const invitesEnabled = Boolean(enabledPages.invites);

  const groupsEnabled =
    Boolean(enabledPages.groups.members) || Boolean(enabledPages.groups.edit);

  const rolesEnabled =
    Boolean(enabledPages.roles.permissions) ||
    Boolean(enabledPages.roles.edit) ||
    Boolean(enabledPages.roles.admins);
  const guestAccessEnabled = Boolean(enabledPages.guestAccess);
  const computersEnabled = Boolean(enabledPages.computers);
  const securityEnabled = Boolean(enabledPages.security);
  const appRulesEnabled = Boolean(enabledPages.appRules);
  const keysEnabled = Boolean(enabledPages.apiKeys);
  const subscriptionEnabled = Boolean(enabledPages.subscription);
  const billingEnabled = Boolean(enabledPages.billing);
  const auditLogsEnabled = Boolean(enabledPages.auditLogs);
  const helpEnabled = Boolean(enabledPages.help);

  if (membersEnabled) {
    router.replace('/members');
  } else if (invitesEnabled) {
    router.replace('/invites');
  } else if (groupsEnabled) {
    router.replace('/groups');
  } else if (rolesEnabled) {
    router.replace('/roles');
  } else if (guestAccessEnabled) {
    router.replace('/guest-access');
  } else if (computersEnabled) {
    router.replace('/computers');
  } else if (securityEnabled) {
    router.replace('/security');
  } else if (appRulesEnabled) {
    router.replace('/app-rules');
  } else if (keysEnabled) {
    router.replace('/keys');
  } else if (subscriptionEnabled) {
    router.replace('/subscription');
  } else if (billingEnabled) {
    router.replace('/billing');
  } else if (auditLogsEnabled) {
    router.replace('/audit-logs');
  } else if (helpEnabled) {
    router.replace('/help');
  } else {
    router.replace('/no-permissions');
  }

  return null;
}

export default HomePage;
